// Typography
// -----------------------------------------------------------------------------
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
@import url('https://fonts.googleapis.com/css?family=Timmana');

body {
  -webkit-font-smoothing: antialiased;
}

img {
  max-width: 100%;
  height: auto;
}