// carousel styles
.carousel-control {
  background-image: none !important;

  .fa {
    font-size: 2em;
    position: absolute;
    top: 49%;
    color: #994336;
  }

  &.left {
    .fa {
      left: 10px;
    }
  }
  &.right {
    .fa {
      right: 10px;
    }
  }
}

.carousel-inner {
  background: #EEEAE9;

  .item {
    line-height: 0;
  }
}
