// Styles for dropping footer to bottom
html {
  height: 100%;
}

#page-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.main-wrapper {
  flex: 1 1 auto;
}

.footer {
  flex: 0 0 auto;
}
body {
  overflow-y: overlay;
}