// Page Layout
// -----------------------------------------------------------------------------

.header {
  min-height: 110px;
  background-color: $brand-primary;

  .container {
    position: relative;
  }

  @media (max-width: $screen-sm - 1) {
    min-height: 30px;
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    right: 0;
    * {
      font-size: .8em;
    }

    .navbar-brand {
      padding: 5px;

      img {
        max-height: 100%;
      }
    }
  }
}

.navigation {
  background-color: #EEEAE9;
}

.nav-tabs {
  margin-top: 20px;
}

.breadcrumb {
  padding: 0px;
  margin: 20px 0;
  list-style: none;
  background-color: #FFF;
  border-radius: 0px;
}

.main-wrapper {
  background-color: #EEEAE9;

  @media (max-width: $screen-sm - 1) {
    margin-top: 50px;
  }
}

.main {
  background-color: #FFF;
  padding-bottom: 20px;
}

.page-node {
  .main img {
    max-width: 100%;
  }
}

.sidebar {
  padding-bottom: 20px;
  .pane-block-1 {
    margin-top: 50px;
  }
}


.footer {
  background-color: $gray-lighter;
  small {
    color: $brand-primary;
    padding: 5px 0;
  }
}

.site-name {
  color: #fff;
  font-size: 42px;
  font-family: 'Josefin Sans', sans-serif;
  margin-top: 18px;
  @media (max-width: $screen-sm - 1) {
    display: none;
  }
}

.site-slogan {
  color: #fff;
  font-size: 20px;
  font-family: 'Timmana', sans-serif;
  margin-left: 100px;
  display: none;
  @include breakpoint(md) {
    margin-left: 250px;
    letter-spacing: 1px;
    display: block;
  }
}

.site-tel {
  color: #fff;

  @include breakpoint(md) {
    float: right;
    margin-top: 25px;
    font-size: 30px;
    margin-right: 65px;
  }

  @media (min-width: $screen-sm) and (max-width: $screen-md - 1 ) {
    float: right;
    margin-top: 25px;
    font-size: 20px;
  }
  @media (max-width: $screen-sm - 1) {
    font-size: 28px;
    margin-top: 5px;
    float: right;
    margin-right: 35px;
  }

  a {
    color: #fff;
    &:hover, &:focus {
      text-decoration: none;
    }
  }

  i {
    display: inline-block;
    margin-right: 10px;
  }
}